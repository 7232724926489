import React from 'react'

import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO/SEO'
import Intro from '../components/Intro/Intro'
import FeatureList from '../components/FeatureList/FeatureList'
import Contact from '../components/Contact/Contact'

const IndexPage = () => (
  <Layout>
    <SEO title="LodaStack 开源分布式监控系统" />
    <Intro />
    <FeatureList />
    <Contact />
  </Layout>
)

export default IndexPage
