import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import Trianglify from 'trianglify'

import styles from './Intro.module.css'

const Intro = () => {
  useEffect(() => {
    const intro = document.getElementById('intro')
    if (!intro) return
    const { width, height } = intro.getBoundingClientRect()
    const pattern = Trianglify({
      width,
      height,
      cell_size: 48,
      x_colors: ['#2e9e4b', '#2e9e4b'],
      y_colors: ['#2e9e4b', '#3dcc62', '#2e9e4b']
    })
    const svg = pattern.svg()
    svg.style.cssText = 'position: absolute; top: 0; right: 0; width: 100%; height: 100%;'
    intro.appendChild(svg)
  })
  return (
    <div id="intro" className={styles.intro}>
      <div className={styles.wrapper}>
        <h2 className={styles.slogan}>开源分布式监控服务</h2>
        <p className={styles.desc}>Monitoring as a Service</p>
        <p className={styles.desc}>强大高性能的数据采集终端，丰富的监控和报警配置，可私有部署的混合云监控，支持阿里云、腾讯云以及自有机房。</p>
        <div className={styles.buttons}>
          <Link className={`${styles.btn} ${styles.btnPrimary}`} to="/docs/getting-started.html">立即试用</Link>
          <a
            className={styles.btn}
            href="http://【服务器地址】:8080/login?loginType=desktop&username=demo&password=demo"
            target="_blank"
            rel="noopener noreferrer"
          >查看演示</a>
          <Link className={styles.btn} to="/docs/contact-us.html">联系我们</Link>
        </div>
      </div>
    </div>
  )
}

export default Intro
