import React from 'react'

import styles from './FeatureList.module.css'

const FeatureList = () => {
  const data = [
    {
      title: '高性能 Agent',
      icon: 'ls-icon-tree',
      introList: [
        '原生支持 Linux/Windows 系统监控',
        '高性能、低内存占用',
        '丰富的数据采集项',
        '支持 Tracing 数据上报'
      ]
    },
    {
      title: '丰富监控配置',
      icon: 'ls-icon-puzzle',
      introList: [
        '丰富多样的插件监控',
        '支持 SDK 自定义监控上报',
        '支持交换机 SNMP 数据采集',
        '服务器链接依赖拓扑和DNS请求探测'
      ]
    },
    {
      title: '低延时多渠道报警',
      icon: 'ls-icon-alert',
      introList: [
        '快至秒级报警触达',
        '可配置多种报警渠道',
        '丰富可组合的报警配置项',
        '支持无值监控报警'
      ]
    },
    {
      title: '企业级权限与安全',
      icon: 'ls-icon-shield',
      introList: [
        '企业级 LDAP 认证支持',
        '快速集成企业微信认证',
        '服务器异常登录上报与审计'
      ]
    },
    {
      title: '私有化部署',
      icon: 'ls-icon-server',
      introList: [
        '支持混合云监控，包括阿里云、腾讯云或自有机房',
        '支持任意数量服务器'
      ]
    },
    {
      title: '开放源代码',
      icon: 'ls-icon-fork',
      introList: [
        '所有核心系统源代码开放，社区协同开发',
        '随时反馈问题，提交贡献代码'
      ]
    }
  ]
  const list = data.map((datum, index) => (
    <div className={styles.item} key={index}>
      <div className={styles.aside}>
        <p className={styles.image}>
          <i className={`lsicon ${datum.icon}`}></i>
        </p>
        <h4>{datum.title}</h4>
      </div>
      <div className={styles.main}>
        <ul>
          {datum.introList.map((v, i) => (
            <li key={i}>{v}</li>
          ))}
        </ul>
      </div>
    </div>
  ))

  return (
    <div className={styles.featureList}>
      <div className={styles.wrapper}>
        {list}
      </div>
    </div>
  )
}

export default FeatureList
